<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            <!-- change E-Mail -->
            <div class="modal-content p-4" v-if="step === 1">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">E-Mail-Adresse ändern</span>
                    <div class="container px-0 mt-4">
                        <div class="row">
                            <div class="col-12 px-0">
                                <input type="text" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="email" placeholder="Neue E-Mail-Adresse festlegen" v-model="$v.email.$model">
                            </div>
                            <div v-if="errorTokenInvalid" class="text-danger" style="font-weight: bold">
                                Token ungültig! E-Mail-Adresse konnte nicht geändert weden.
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="errorTokenInvalid" class="modal-footer border-0 p-0 mt-4">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-6 offset-3">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="isLoading" @click="close">
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="modal-footer border-0 p-0 mt-4">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-6">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="isLoading" @click="close">
                                    Abbrechen
                                </button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="$v.$invalid || isLoading" @click="doEmailChange">
                                    Durchführen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- change EMail: success -->
            <div class="modal-content p-4" v-if="step === 2">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">E-Mail-Adresse ändern</span>
                    <div class="container px-0 mt-4">
                        <div class="row">
                            <div class="col-12 px-0">
                                Deine E-Mail-Adresse wurde erfolgreich geändert!
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer border-0 p-0 mt-4">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-6 offset-3">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" @click="close">
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import Conf from "../../configs";
    import BaseModal from "./BaseModal";
    import {required, email} from "vuelidate/lib/validators";
    import {apiCall} from "../../helpers";
    import {mixinBasics} from "../../mixins/basics";
    import {mapActions} from "vuex";


    const api = Conf.restApi.endpoints.map;


    export default {
        name: "EmailChange",

        extends: BaseModal,

        mixins: [
          mixinBasics
        ],

        props: {
            token: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                modalId: 'email-change-modal',
                modal: undefined,
                isLoading: false,

                toastMsg: '',
                toastTitle: '',
                toastBgColor: undefined,
                showToast: false,

                step: 1,
                email: undefined,
                errorTokenInvalid: false
            }
        },

        validations: {
            email: {
                required, email
            },
        },

        methods: {
            ...mapActions('main', {
                loadUserProfile: 'loadUserProfile'
            }),
            async doEmailChange() {
                this.isLoading = true;
                let payload = {email: this.email, token: this.token};
                let req = api.user.email.set;
                let res = await apiCall(req.url, req.method, payload)

                if (res.error === 0) {
                    await this.loadUserProfile(true);
                    this.step = 2;
                } else {
                    this.errorTokenInvalid = true;
                }
                this.isLoading = false;
            },

        }
    }
</script>



<style scoped>

</style>